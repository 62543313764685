/****************************
           Page 1
***************************/
.pageone-wrapper-div{
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #51abe0;
    border-bottom: 2px solid #fff;
}
.page-onetop{
    margin: 0px auto;
    width: 1000px;
    text-align: center;
}
.pageone-image-wrapper{
    width: 100%;
    float: left;
    margin: 0px;
    padding: 0px;
    background-color: #ffffff;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}
.page-onetop img{
    display: inline-block;
    height: 441px;
    width: 502px;
}
.maincol{
    padding: 50px 0px;
    width: 1000px;
    margin: 0px auto;
}
.maincol h1{
    float: right;
    padding: 20px 0px 40px 0px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 35px;
    font-family: 'montserratregular';
}

/****************************
           Page 2
***************************/
.page-two-wrapper{
    float: left;
    width: 100%;
    margin: 0px;
    padding: 50px 0px 0px 0px;
    background-color: #51a9de;
}
.half-section{
    width: 1000px;
    margin: 0px auto;
    padding: 0px;
    text-align: center;
}
.half-section h1{
    float:left;
    width: 100%;
    color: #fff;
    font-size: 35px;
    font-family: 'montserratregular';
}
.half-section p{
    float:left;
    width: 100%;
    color: #fff;
    font-size: 20px;
    padding: 20px 0px;
    font-family: 'scala_sansregular';
}
.b-cf-info{
    color: #3a3939 !important;
}
.half-section img{
    padding: 0px 0px 0px 0px;
}
.secon-section{
    width: 100%;
    float: left;
    padding: 50px 0px 0px 0px;
    margin: 0px auto;
}
.inner-section{
    width: 1000px;
    margin: 0px auto;
    text-align: center;
    height: 500px;
}
.inner-section h1{
    float:left;
    color: #50bc87;
    font-size: 35px;
    width: 100%;
    text-align: right;
    font-family: 'montserratregular';
}
.inner-section p{
    width: 100%;
    float: left;
    padding: 50px 0px;
    font-size: 20px;
    color: #e66e25;
    font-family: 'scala_sansregular';
}
.inner-section img{
    float: right;
    padding: 100px 0px 0px 0px;
}

/****************************
           Page 3
***************************/
.page-wrapper-three{
    width: 100%;
    float: left;
    background-color: #ffffff;
}
.header{
    width:100%;
    height: 120px;
    background-color: #51abe0;
    float: left;
}
.inner-header{
    width: 1000px;
    margin: 0px auto;
}
.inner-header h1{
    float: left;
    width: 100%;
    font-size: 35px;
    color: #fff;
    padding: 40px 0px 0px 0px;
    font-family: 'montserratregular';
}
.disclaimer-title{
    float: left;
    width: 100%;
    font-size: 20px !important;
    color:  #444444 !important;
    padding: 20px 0px 0px 0px;
    font-family: 'scala_sansregular';
}
.disclaimer{
    float: left;
    width: 100%;
    font-size: 18px !important;
    color:  #5e5d59 !important;
    padding: 20px 0px 20px;
    font-family: 'scala_sansregular';
    font-style: italic;
}
.infog-wrap{
    width: 1000px;
    margin: 0px auto;
    padding:40px 0px;
    text-align: center;
}
.infog-wrap a{
    font-family: 'scala_sansregular';
    font-size: 20px !important;
    color:  #444444 !important;
    text-decoration: none;
    transition: all 0.5s ease;
}
.infog-wrap a:hover{
    transition: all 0.5s ease;
    text-decoration: underline;
}
.infog-wrap img{
    padding: 100px 0px 0px 0px;
}
.infog-wrap h1{
    float: left;
    width: 100%;
    font-size: 30px;
    color:  #e66e25;
    padding: 40px 0px 0px 0px;
    font-family: 'montserratregular';
}
.infog-wrap img{
    padding: 20px 0px 0px 0px;
}
.infog-wrap span{
    float: left;
    width: 100%;
    font-size: 25px;
    color:  #51abe0;
    padding: 20px 0px 0px 0px;
    font-family: 'scala_sansregular';
}
.infog-wrap p{
    float: left;
    width: 100%;
    font-size: 20px;
    color:  #444444;
    padding: 20px 0px 20px;
    font-family: 'scala_sansregular';
}
.box-wrpper{
    float: left;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: 0px auto;
}
.mission-wrapper{
    background-color: #e66e25;
    float: left;
    width: 450px;
    margin: 0px 20px 0px 0px;
    padding: 20px;
    box-sizing: border-box;
    height: 180px;
}
.objectives-wrapper{
    background-color: #51a9de;
    float: left;
    width: 450px;
    margin: 0px 0px 0px 0px;
    padding: 20px;
    box-sizing: border-box;
    height: 180px;
}
.objectives-wrapper h1{
    float: left;
    width: 100%;
    font-size: 25px;
    color:  #ffffff;
    padding: 0px 0px 0px 0px;
    font-family: 'montserratregular';
}
.objectives-wrapper p{
    float: left;
    width: 100%;
    font-size: 20px;
    color:  #ffffff;
    padding: 10px 0px 0px 0px;
    font-family: 'scala_sansregular';
}
.mission-wrapper h1{
    float: left;
    width: 100%;
    font-size: 25px;
    color:  #ffffff;
    padding: 0px 0px 0px 0px;
    font-family: 'montserratregular';
}
.mission-wrapper p{
    float: left;
    width: 100%;
    font-size: 20px;
    color:  #ffffff;
    padding: 20px 0px 0px 0px;
    font-family: 'scala_sansregular';
}
/****************************
           Page 4
***************************/
.page-wrapper-four{
    float: left;
    width: 100%;
    margin: 0px;
    padding: 50px 0px 0px 0px;
    background-color: #50bc87;
}
.investment_r_wrapper{
    padding: 80px 0px 0px 0px;
}
.investment_r_wrapper span{
    width: 100%;
    float: left;
    color: #ffffff;
    font-size: 25px;
    font-family: 'scala_sansregular';
}
.investment_r_wrapper p{
    width: 100%;
    float: left;
    padding: 20px 0px;
    font-size: 20px;
    color: #ffffff;
    font-family: 'scala_sansregular';
}
.secon-section{
    width: 100%;
    float: left;
    background-color: #fff;
    text-align: left;
    padding: 20px;
    box-sizing: border-box;
}
.inner-section-c{
    background: #fff;
    width: 1000px;
    margin: 0px auto;
    height: 500px;
}
.inner-section-c h1{
    float: left;
    width: 100%;
    padding: 0px 0px 40px 0px;
    color:#50bc87;
    font-size: 35px;
    font-family: 'montserratregular';
}
.inner-section-c p{
    float: left;
    width: 100%;
    padding: 0px 0px 40px 0px;
    color:#50bc87;
    font-size: 20px;
    font-family: 'scala_sansregular';
}

/****************************
           Page 5
***************************/
.cf-image-wrapper{
    float: left;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
}
.cf-image-wrapper h1{
    float: left;
    width: 100%;
    text-align: center;
    color: #e66e25;
    font-size: 30px;
    font-family: 'montserratregular';
    padding: 30px 0px;
}
.cf-image-wrapper p{
    float: left;
    width: 100%;
    text-align: center;
    color: #e66e25;
    font-size: 20px;
    font-family: 'scala_sansregular';
    padding: 30px 0px;
}

/****************************
           Page 6
***************************/
.faq-wrapper{
    float: left;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: 0px;
}
.faq-inner{
    width: 1000px;
    margin: 0px auto;
}
.half-section span{
    color: #fff;
    font-size: 25px;
    float: left;
    width: 100%;
    padding: 10px 0px;
    font-family: 'scala_sansregular';
}
.eq-cf{
    text-align: center;
    padding: 20px;
    font-family: 'scala_sansregular';
    font-size: 30px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #ffffff;
}
.faq-inner p{
    color:#50bc87;
    font-size: 20px;
    width: 100%;
    float: left;
    padding: 10px 0px;
    font-family: 'scala_sansregular';
}

/****************************
           Page 8
***************************/
.l-e-wrapper{
    width:100%;
    margin: 0px;
    padding: 20px;
    box-sizing: border-box;
    clear: both;
    float: left;
}
.l-e-inner{
    width: 1000px;
    margin: 0px auto;
    padding: 0px;
}
.l-e-inner h1{
    float: left;
    width: 100%;
    padding: 0px 0px 40px 0px;
    color:#50bc87;
    font-size: 35px;
    font-family: 'montserratregular';
}
.l-e-inner p{
    float: left;
    width: 100%;
    padding: 0px 0px 40px 0px;
    color:#50bc87;
    font-size: 20px;
    font-family: 'scala_sansregular';
}
.application-wrapper{
    float: left;
    width: 100%;
}
.application-inner{
    width: 1000px;
    margin: 0px auto;
}
.application-inner h1{
    float: left;
    width: 100%;
    padding: 0px 0px 40px 0px;
    color:#50bc87;
    font-size: 35px;
    font-family: 'montserratregular';
}
.financial-doc{
    float: left;
    width: 100%;
    padding: 0px;
    color:#50bc87;
    font-size: 20px;
    font-family: 'montserratregular';
}
.financial-doc li{
    float: left;
    width: 100%;
    padding: 15px 0px 0px 20px;
    color:#50bc87;
    font-size: 16px;
    font-family: 'scala_sansregular';
}
.checklist-li{
    float: left;
    width: 100%;
    padding: 0px;
    color:#50bc87;
    font-size: 20px;
    padding: 15px 0px 0px 0px;
    font-family: 'montserratregular';
}
/**********************************
             Page 9
*********************************/
.inner-headline{
    color: #50bc87;
    float: left;
    font-family: "montserratregular";
    font-size: 20px;
    padding: 15px 0px;
    width: 100%;
}
.i-wrapper{
    float: left;
    width: 50%;
    padding: 0px 0px 40px 0px;
}
.fit-headline{
    color: #ffffff;
    float: left;
    font-family: "scala_sansregular";
    font-size: 25px;
    padding: 10px 0;
    width: 50%;
}
.i-image-wrapper{
    float: right;
    width: 50%;
}
.i-image-wrapper img{
    float: right;
}
/**********************************
             Page 10
*********************************/
.keypeople-wrapper{
    width: 100%;
    float: left;
    padding: 20px;
    box-sizing: border-box;
    margin: 0px;
}
.member-image{
    float: left;
    width: 270px;
    padding: 0px;
    margin: 0px;
}
.member-info{
    float: right;
    width: 680px;
}
/**********************************
             Page 12
*********************************/
.download-pdf{
    width: 100%;
    float: left;
    padding: 30px 0px 0px 0px;
    margin: 0px;
    text-align: center;
    height: 100px;
}
.one-third{
    display:inline-block;
}
a.emerald-flat-button {
    /*position: relative;
    width: 300px;
    height: 60px;
    padding: 0;*/
    background: #27ae60;
    border: 0;
    border-bottom: 2px solid #109749;
    border-color: -moz-use-text-color -moz-use-text-color #109749;
    border-style: none none solid;
    border-width: 0 0 2px;
    box-shadow: 0 -2px #109749 inset;
    -webkit-box-shadow: inset 0 -2px #109749;
    color: #ffffff !important;
    cursor: pointer;
    font-family: "scala_sansregular";
    font-size: 24px !important;
    padding: 15px 65px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    vertical-align: top;
    transition: none;
}
a.emerald-flat-button:hover{
    background: #28ba65;
    color: white;
    text-decoration: none;
    transition: none;
}
.emerald-flat-button:active {
    top: 1px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.header-border{
    border-top: 2px solid #74c595;
}

.header-border{
    border-top: 2px solid #74c595;
}
